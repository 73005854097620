/* CSS for the TermsOfService component */
.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .terms-text {
    text-align: justify;
  }
  
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  li {
    margin-bottom: 20px;
  }
  
  strong {
    font-weight: bold;
  }
  