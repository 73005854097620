.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.03);
    /* Fallback for browsers that do not support backdrop-filter */
    backdrop-filter: blur(2px);
    /*Add the backdrop filter for blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 25px;
    /* width: 55%; */
}

.blog-card {
    width: 100%;
}

.get-shadow {
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.15);
}

.offset {
    --color: #19bc8b;
    --hover: black;

    box-shadow:
        0.3em 0.3em 0 0 var(--color),
        inset 0.3em 0.3em 0 0 var(--color);

    transition: box-shadow 0.25s ease-in-out;
}

.offset:hover,
.offset:focus {
       box-shadow:
        0 0 0 0 var(--hover),
        inset 10em 5.5em 0 0 var(--hover);
}



@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 480px) {}