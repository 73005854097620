.darkColor {
  color: #210062;
}

.custom-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #17A2B8;
  border-radius: 50%;
  position: relative;
  top: 3px;
  display: inline-block;
  height: 18px;
  width: 18px;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.up-down {
  z-index: 99999;
  position: absolute;
  left: 85%;
  top: 45vh;
}


.empty-library-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
}

.empty-library-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  max-width: 600px;
  text-align: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.empty-library-image {
  width: 200px;
  height: auto;
  margin: 0 auto;
}

.empty-library-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.empty-library-text {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 2rem;
}

.empty-library-button {
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.empty-library-button:hover {
  background-color: #0056b3;
}
