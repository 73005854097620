.upload-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #787878;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    background-color: #f2f2f2;
    transition: background-color 0.3s ease-in-out;
  }
  
  .upload-button-container:hover {
    background-color: #ebebeb;
  }
  
  .upload-button-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .upload-icon {
    font-size: 48px;
    color: #787878;
    margin-bottom: 12px;
  }
  
  .upload-icon:hover {
    color: #4338ca;
  }
  
  .upload-button-label p {
    font-size: 16px;
    color: #4f4f4f;
    margin: 0;
  }
  
  /* Hide the file input */
  .upload-button-label input[type="file"] {
    display: none;
  }
  
  /* MySubscriptionDetails.css */
.subscription-details-container {
  min-height: 100vh;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.page-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.subscription-table-container {
  max-width: 800px;
  width: 100%;
  overflow-x: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.subscription-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #333;
}

.subscription-table th,
.subscription-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #444;
}

.subscription-table th {
  background-color: #444;
  color: #fff;
}

.subscription-table td {
  background-color: #222;
}

.cancel-button {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.cancel-button:hover {
  background-color: #d32f2f;
}
