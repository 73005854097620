/* .navbar-custom {
  background-color: #4C63F5;
} */

.contract {
  /* margin-top: -120px;
  transform: scale(0.9); */
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 0rem;
}


.loader {
  margin-top: 30vh;
  /* margin-left: 20vw; */
}

.red {
  color: red;
}

#edit {
  font-size: smaller;
}

.writetous {
  /* text-decoration: underline; */
  color: blue;
  cursor: pointer;
}

.rounded-cs {
  border-radius: 25px;
}