
.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.privacy-container h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.privacy-container h2 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-container p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.privacy-container ol {
  margin-left: 40px;
}

.privacy-container strong {
  font-weight: bold;
}
