/* For The Toggle Button */
.toggle-button {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 30px;
  border-radius: 15px;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button-label {
  width: 60px;
  position: absolute;
  /* width: 80px; */
  /* top: -30%; */
  /* left: 20%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: black;
}

.toggle-button.active {
  background-color: #8AFF8A;
}

.toggle-button.active .toggle-button-label {
  /* color: white; */
  left: calc(100% - 50%);
  transform: translate(50%, -50%);
}

.toggle-button:before {
  content: "";
  position: absolute;
  top: 0.8px;
  left: 1px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s;
}

.toggle-button.active:before {
  transform: translateX(60px);
}

.add-margin {
  margin-left: -80px;
}

/* For The Toggle Button */

.round-btn {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.plus-position {
  position: relative;
  right: 2px;
  bottom: 1.5px;
}



.scrollable-span {
  /* align-items: ; */
  text-align: left;
  /* width: 80%; */
  display: inline-block;
  overflow-x: auto;
  white-space: nowrap;
  vertical-align: top;
}

/* width height */
.scrollable-span::-webkit-scrollbar {
  height: 6px;
}

/* Track */
.scrollable-span::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scrollable-span::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.scrollable-span::-webkit-scrollbar-thumb:hover {
  background: #007BFF;
}




.scrollable-span-right {
  /* align-items: ; */
  text-align: left;
  width: 75%;
  display: inline-block;
  overflow-x: auto;
  white-space: nowrap;
  vertical-align: bottom;
}
@media only screen and (max-width: 1200px) {
  .scrollable-span-right {
    width: 60%;
  }
}
@media only screen and (max-width: 900px) {
  .scrollable-span-right {
    width: 55%;
  }
}
@media only screen and (max-width: 600px) {
  .scrollable-span-right {
    width: 50%;
  }
}



/* width height */
.scrollable-span-right::-webkit-scrollbar {
  height: 6px;
}

/* Track */
.scrollable-span-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scrollable-span-right::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.scrollable-span-right::-webkit-scrollbar-thumb:hover {
  background: black;
}