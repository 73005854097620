@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #eee;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  filter: grayscale(100%);
}


.custom-video {
  background-color: #f0f0f0;
  /* Background color for the container */
  padding: 20px;
  border-radius: 10px;
  /* Rounded corners for the container */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow effect */
}