.mar-custom-1 {
    margin-top: 39px;
}

.mar-custom-2 {
    margin-top: 4px;
}



.color-fg-sponsors-col path {
    fill: white;
}