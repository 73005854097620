.contact-popup {
  position: relative;
}

.contact-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.popup {
  /* position: absolute; */
  /* top: 100%; */
  /* left: 0; */
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.close-button {
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}



.icon::after{
  content: '';
  display: block;
  position: absolute;
  border-top: 25px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 18px solid #007bff;
  left: 100%;
  top: 0;
}


.upload2-button-container {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #787878;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  background-color: #f2f2f2;
  transition: background-color 0.3s ease-in-out;
}

.upload2-button-container:hover {
  background-color: #ebebeb;
}

.upload2-button-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.upload2-icon {
  font-size: 48px;
  color: #787878;
  margin-bottom: 12px;
}

.upload2-icon:hover {
  color: #4338ca;
}

.upload2-button-label p {
  font-size: 16px;
  color: #4f4f4f;
  margin: 0;
}

/* Hide the file input */
.upload2-button-label input[type="file"] {
  display: none;
}